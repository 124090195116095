<template>
  <breakpoints v-slot="{ aboveLg }">
    <div class="world-map relative" ref="map" v-show="aboveLg">
      <div
        v-if="selectedCountry"
        class="map-country-modal"
        :class="side == 'right' ? 'left-12' : 'right-12'"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-2">
            <Flag :code="selectedCountryCode" class="h-[20px]" />
            <h5 class="text-[22px] font-bold text-black">
              {{ selectedCountry.name }}
            </h5>
          </div>

          <button @click="closeModal()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div v-html="selectedCountry.text" class="mt-2 text-lg font-normal leading-casual"></div>
        <div class="grid grid-cols-3 gap-2 mt-2">
          <div v-for="sdg in selectedCountry.sdgs" :key="sdg.id" class="col-span-1">
            <ImgixImage :image="sdg.image" :height="84" :width="84" fit="max" />
          </div>
        </div>
      </div>
    </div>
  </breakpoints>
</template>

<script>
import L from 'leaflet';
import Flag from './Flag.vue';
import ImgixImage from '../../admin/asset-manager/components/ImgixImage.vue';

require('leaflet/dist/leaflet.css');

import mapImage from '../map/world-map.svg';

// That corresponds to the SVG image
const MAP_BOUNDS = [
  [0, 0],
  [650, 1100]
];

// Bounds for the default view.
const DEFAULT_VIEW_BOUNDS = [
  [0, 0],
  [660, 1100]
];

export default {
  name: 'Map',

  components: { Flag, ImgixImage },

  props: {
    countries: { required: true, type: Array }
  },

  data() {
    return {
      selectedCountryCode: null,
      selectedCountry: null,
      svg: null,
      side: 'right'
    };
  },

  async mounted() {
    // Create the map
    const mapImageContent = await (await fetch(mapImage)).text();
    const parser = new DOMParser();
    const mapImageSvg = parser.parseFromString(mapImageContent, 'image/svg+xml').documentElement;

    const map = L.map(this.$refs.map, {
      crs: L.CRS.Simple,
      attributionControl: false,
      maxBounds: [],
      zoomSnap: false,
      minZoom: -0.75,
      maxZoom: 2.5,
      zoomControl: false
    });
    this.map = map;

    // Disable map zoom and drag
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    this.map.dragging.disable();

    // Add zoom controls
    // L.control.zoom({ position: 'bottomright' }).addTo(map);

    // Add the map image
    const mapSvgImage = L.svgOverlay(mapImageSvg, MAP_BOUNDS, {
      className: 'world-map-svg-overlay'
    }).addTo(map);

    // Reset to the default view of the map
    this.resetToDefaultView();

    const svg = mapSvgImage.getElement();
    this.svg = svg;
    // Highlight all countries with information on the map
    this.countries.forEach((item) => {
      console.log(item);
      setTimeout(() => {
        const selector = `path[data-code="${item['country_code'].toLowerCase()}"]`;

        svg.querySelectorAll(selector).forEach((el) => el.classList.add('active'));
      }, 100);
    });

    // Highlight with different color the selected country
    setTimeout(() => {
      svg.addEventListener('click', (e) => {
        this.clickOnSide(e);

        svg.querySelectorAll('.active').forEach((el) => {
          if (e.target == el) {
            this.selectedCountryCode = el.getAttribute('data-code');
            svg.querySelectorAll('path.selected').forEach((el) => el.classList.remove('selected'));

            el.classList.add('selected');
            this.loadModal();
          }
        });
      });
      100;
    });
  },

  methods: {
    loadModal() {
      this.selectedCountry = this.countries.find(
        (x) => x.country_code.toLowerCase() == this.selectedCountryCode
      );
    },

    closeModal() {
      this.selectedCountry = null;
      this.svg.querySelectorAll('path.selected').forEach((el) => el.classList.remove('selected'));
    },

    clickOnSide(e) {
      const divWidth = this.$refs.map.getBoundingClientRect().width;

      const halfDivWidth = divWidth / 2;
      const mouseXPos = e.offsetX;

      if (mouseXPos <= halfDivWidth) {
        this.side = 'left';
      } else {
        this.side = 'right';
      }
    },

    resetToDefaultView() {
      this.map.fitBounds(DEFAULT_VIEW_BOUNDS);
    }
  }
};
</script>

<style lang="scss">
.leaflet-container {
  @apply bg-[#5B92E5];
}

.world-map {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.world-map-svg-overlay {
  fill: #f7f5f2;

  path {
    @apply transition-colors;
  }

  path.active {
    fill: #e61878;
  }

  path.active.selected {
    fill: #850842;
  }
}

.map-country-modal {
  position: absolute;
  top: -8px;
  z-index: 1000;
  @apply bg-white rounded p-6;
  border-top: 4px solid #e61878;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.16);
  width: 340px;
}

.leaflet-pane .world-map-svg-overlay path {
  pointer-events: auto;
}
</style>
