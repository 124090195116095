<template>
  <div class="glide">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides items-center">
        <slot></slot>
      </ul>
    </div>
    <div class="glide__arrows" data-glide-el="controls">
      <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.1" cx="24" cy="24" r="24" fill="#28A9E0" />
          <g clip-path="url(#clip0_2928_6209)">
            <path
              d="M32 23H19.83L25.415 17.415L24 16L16 24L24 32L25.415 30.585L19.83 25H32V23Z"
              fill="#0A4B7C"
            />
          </g>
          <defs>
            <clipPath id="clip0_2928_6209">
              <rect width="24" height="24" fill="white" transform="translate(12 12)" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.1" cx="24" cy="24" r="24" fill="#28A9E0" />
          <g clip-path="url(#clip0_2928_6210)">
            <path
              d="M16 23H28.17L22.585 17.415L24 16L32 24L24 32L22.585 30.585L28.17 25H16V23Z"
              fill="#0A4B7C"
            />
          </g>
          <defs>
            <clipPath id="clip0_2928_6210">
              <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 36 12)" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
    <div class="glide__bullets" data-glide-el="controls[nav]">
      <button class="glide__bullet" data-glide-dir="=0"></button>
      <button class="glide__bullet" data-glide-dir="=1"></button>
    </div>
    <div class="total-number" data-glide-el="controls[nav]">
      <button class="glide__bullet" data-glide-dir="=0">1 of 2</button>
      <button class="glide__bullet" data-glide-dir="=1">2 of 2</button>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';
import { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  mounted() {
    if (!this.$el.querySelectorAll('.glide__slide').length) {
      return; // No slides, no Glide
    }

    new Glide(this.$el, {
      type: 'carousel',
      perView: 1,
      gap: 0,
      autoplay: 5000
    }).mount({
      Controls,
      Swipe,
      Breakpoints
    });
  }
};
</script>

<style lang="scss">
@import '@glidejs/glide/dist/css/glide.core.min.css';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

.glide__arrow {
  border: none;
  box-shadow: none;
}

.glide__arrow--left {
  left: -1em;

  @screen xl {
    left: -6em;
  }
}

.glide__arrow--right {
  right: -1em;

  @screen xl {
    right: -5em;
  }
}

.glide__bullets {
  bottom: -3em;
  left: 36px;
  align-items: center;

  .glide__bullet {
    width: 23px;
    height: 23px;
    margin-right: 16px;
    border: none !important;
    padding: 0;
  }

  .glide__bullet--active {
    background-color: transparent;
    background-image: url('./images/icons/play.svg');
    background-size: cover;
    background-position: center;
    width: 28px;
    height: 28px;
  }
}

.total-number {
  position: absolute;
  bottom: -3em;
  right: 0;

  .glide__bullet {
    display: none;
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .glide__bullet--active {
    display: block;
    @apply text-white text-base font-normal;
    width: 50px;
    height: 30px;
  }
}
</style>
