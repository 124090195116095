<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
import mapValues from 'lodash/mapValues';
import each from 'lodash/each';
import upperFirst from 'lodash/upperFirst';

export default {
  render() {
    // We pass all computed properties as slot props
    return this.$scopedSlots.default(this.allComputedProperties());
  },

  data() {
    return { currentWidth: window.innerWidth };
  },

  created() {
    window.addEventListener('resize', this.captureWidth);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.captureWidth);
  },

  computed: {
    // We generate two computed properties for each screen:
    // - aboveScreen -> boolean
    // - belowScreen -> boolean
    ...(function() {
      const screens = mapValues(resolveConfig(tailwindConfig).theme.screens, parseInt);
      const properties = {};

      each(screens, (width, name) => {
        properties[`below${upperFirst(name)}`] = function() {
          return this.currentWidth < width;
        };
        properties[`above${upperFirst(name)}`] = function() {
          return this.currentWidth >= width;
        };
      });

      return properties;
    })()
  },

  methods: {
    captureWidth() {
      this.currentWidth = window.innerWidth;
    },

    allComputedProperties() {
      const computedProperties = {};

      Object.keys(this.$options.computed).forEach(
        (name) => (computedProperties[name] = this[name])
      );

      return computedProperties;
    }
  }
};
</script>
