<template>
  <div class="map bg-white relative mt-6">
    <Map :countries="countries" class="lg:block hidden" />
    <MobileMap :countries="countries" class="lg:hidden" />
  </div>
</template>

<script>
import Map from './Map.vue';
import MobileMap from './MobileMap.vue';

export default {
  components: { Map, MobileMap },

  props: {
    countries: { required: true, type: Array }
  }
};
</script>

<style lang="scss">
.map {
  @screen lg {
    @apply min-h-[600px] max-h-screen;
  }

  @screen sm {
    @apply bg-center bg-cover bg-no-repeat;
    /* background-image: url('./world-map.svg'); */
  }

  @screen lg {
    background-image: none;
  }
}
</style>
