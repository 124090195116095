<template>
  <img :src="src" :height="round(_height)" :width="round(_width)" :alt="_alt" />
</template>

<script>
import imgixURL from '../helpers/imgix-url';

const BLANK_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export default {
  name: 'ImgixImage',

  props: {
    alt: String,
    crop: String,
    crossorigin: { type: String, default: 'anonymous' },
    fit: { type: String, default: 'crop' },
    height: Number,
    width: Number,
    image: Object,
    options: Object
  },

  data() {
    return { src: BLANK_IMAGE };
  },

  computed: {
    _alt() {
      return this.alt || this.image.path.split('/').pop();
    },

    _height() {
      return this.height || (this.width && this.width / this.aspectRatio);
    },

    _width() {
      return this.width || (this.height && this.height * this.aspectRatio);
    },

    aspectRatio() {
      return this.image.meta.width / this.image.meta.height;
    }
  },

  mounted() {
    let src = this.getImgixSrc();
    this.loadImage(src).then((src) => (this.src = src));
  },

  methods: {
    round(value) {
      return Math.round(value);
    },

    getDPR() {
      return (window.devicePixelRatio || 1).toFixed(2);
    },

    getImgixSrc(overwrites = {}) {
      return imgixURL(this.image.path, {
        ...this.options,
        crop: this.crop,
        fit: this.fit,
        h: this.height,
        w: this.width,
        dpr: this.getDPR(),
        ...overwrites
      });
    },

    loadImage(src) {
      return new Promise((resolve) => {
        this.loadingImage = new Image();
        this.loadImageCallback = () => resolve(src);

        this.loadingImage.addEventListener('load', this.loadImageCallback, true);
        this.loadingImage.addEventListener('error', this.loadImageCallback, true);
        this.loadingImage.crossOrigin = 'Anonymous';
        this.loadingImage.src = src;

        // Resolve with the cached image if present
        if (this.loadingImage.complete || this.loadingImage.readyState === 4) {
          resolve(src);
        }
      });
    }
  }
};
</script>
