function optionsToQuery(options) {
  return Object.keys(options)
    .map((key) => options[key] && `${key}=${options[key]}`)
    .filter((v) => v)
    .sort()
    .join('&');
}

export default function imgixURL(path, options = {}) {
  let host = process.env.IMGIX_HOST;
  let query = optionsToQuery(options);

  return `https://${host}/${path}?${query}`;
}
