<template>
  <div class="glide">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides items-center">
        <slot></slot>
      </ul>
    </div>
    <div class="glide__arrows" data-glide-el="controls">
      <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.1" cx="24" cy="24" r="24" fill="#28A9E0" />
          <g clip-path="url(#clip0_2928_6209)">
            <path
              d="M32 23H19.83L25.415 17.415L24 16L16 24L24 32L25.415 30.585L19.83 25H32V23Z"
              fill="#0A4B7C"
            />
          </g>
          <defs>
            <clipPath id="clip0_2928_6209">
              <rect width="24" height="24" fill="white" transform="translate(12 12)" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.1" cx="24" cy="24" r="24" fill="#28A9E0" />
          <g clip-path="url(#clip0_2928_6210)">
            <path
              d="M16 23H28.17L22.585 17.415L24 16L32 24L24 32L22.585 30.585L28.17 25H16V23Z"
              fill="#0A4B7C"
            />
          </g>
          <defs>
            <clipPath id="clip0_2928_6210">
              <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 36 12)" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';
import { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  props: {
    gap: String
  },

  mounted() {
    if (!this.$el.querySelectorAll('.glide__slide').length) {
      return; // No slides, no Glide
    }

    new Glide(this.$el, {
      type: 'carousel',
      perView: 3,
      gap: +this.gap,
      autoplay: 5000,
      breakpoints: {
        1280: {
          perView: 3
        },
        1024: {
          perView: 3,
          gap: +this.gap
        },
        1023: {
          perView: 3,
          gap: 30
        },
        768: {
          perView: 2,
          gap: 30
        },
        480: {
          perView: 1
        }
      }
    }).mount({
      Controls,
      Swipe
    });
  }
};
</script>

<style lang="scss">
@import '@glidejs/glide/dist/css/glide.core.min.css';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

.glide__arrow {
  border: none;
  box-shadow: none;
}

.glide__arrow--left {
  left: -1em;

  @screen xl {
    left: -6em;
  }
}

.glide__arrow--right {
  right: -1em;

  @screen xl {
    right: -5em;
  }
}
</style>
