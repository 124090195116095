<template>
  <img :src="flagUrl" />
</template>

<script>
const flags = require.context('../images/country_flags/', false, /.*\.png$/);

export default {
  props: {
    code: { required: true, type: String }
  },

  computed: {
    flagUrl() {
      return flags(`./${this.code.toLowerCase()}.png`);
    }
  }
};
</script>
