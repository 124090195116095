<template>
  <iframe
    ref="iframe"
    title="The title"
    :src="`https://app.livecasts.eu/embed-full/${streamId}`"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen=""
  ></iframe>
</template>

<script>
import iframeResize from 'iframe-resizer/js/iframeResizer';

export default {
  props: {
    streamId: { required: true, type: String }
  },

  mounted() {
    iframeResize({}, this.$refs.iframe);
  },

  beforeDestroy() {
    if (typeof this.$refs.iframe.iFrameResizer === 'undefined') {
      return;
    }

    this.$refs.iframe.iFrameResizer.removeListeners();
  }
};
</script>

<style lang="scss" scoped>
iframe {
  border: none;
  width: 1px;
  min-width: 100%;
}
</style>
