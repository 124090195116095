import Vue from 'vue';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import '../admin/public/bootstrap';
import LoadScript from '@common/components/LoadScript.vue';
import Navigation from '../../javascript/application/navigation';
import LivecastsStream from '../../javascript/application/LivecastsStream';
import PlausibleEvent from '../../javascript/application/PlausibleEvent';
import CookiesNotice from '../../javascript/application/CookiesNotice';
import Slider from '../../javascript/application/Slider.vue';
import VideoSlider from '../../javascript/application/VideoSlider.vue';
import WorldMap from '../../javascript/application/map/WorldMap.vue';
import Breakpoints from '../../javascript/application/map/Breakpoints.vue';

require('../styles/application.scss');

// Add all images from the "images" folder to the pipeline
require.context('../application/images', true);

Rails.start();
Turbolinks.start();
require.context('../application/images', true);

// Import all images <%= image_pack_tag 'rails.png' %>
require.context('../application/images', true);

Vue.component('load-script', LoadScript);
Vue.component('navigation', Navigation);
Vue.component('livecasts-stream', LivecastsStream);
Vue.component('plausible-event', PlausibleEvent);
Vue.component('cookies-notice', CookiesNotice);
Vue.component('slider-component', Slider);
Vue.component('video-slider', VideoSlider);
Vue.component('world-map', WorldMap);
Vue.component('breakpoints', Breakpoints);

let vueApp;

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  vueApp = new Vue({ el: '#js-wrapper' });
});

document.addEventListener('turbolinks:visit', () => {
  if (vueApp) vueApp.$destroy();
});
