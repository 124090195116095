<script>
/* global plausible */

export default {
  props: {
    name: String,
    props: Object
  },

  mounted() {
    console.log(this.name, { props: this.props });
    plausible(this.name, { props: this.props });
  },

  render() {
    return null;
  }
};
</script>
