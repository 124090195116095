<template>
  <div class="container countries-wrapper grid grid-cols-1 gap-8">
    <div v-for="country in this.countries" :key="country.id" class="col-span-1 country-button">
      <button
        class=" focus:outline-none flex justify-between items-center  w-full"
        @click="selectedCountryId = country.id"
      >
        <div class="flex items-center space-x-2">
          <Flag :code="country.country_code.toLowerCase()" class="h-[20px]" />
          <h5 class="text-[20px] font-bold text-black">
            {{ country.name }}
          </h5>
        </div>
        <svg
          class="w-3 transition-all duration-200 transform"
          :class="{
            'rotate-180': selectedCountryId == country.id,
            'rotate-0': selectedCountryId != country.id
          }"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 10"
          aria-hidden="true"
        >
          <path
            d="M15 1.2l-7 7-7-7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div v-if="selectedCountryId == country.id" class="transition-all duration-200 ">
        <div v-html="country.text" class="mt-2 text-lg font-normal leading-casual"></div>
        <div class="grid grid-cols-2 xs:grid-cols-4 sm:grid-cols-5 md:grid-cols-6 gap-4 mt-4 mb-8">
          <div v-for="sdg in country.sdgs" :key="sdg.id" class="col-span-1">
            <ImgixImage :image="sdg.image" :height="84" :width="84" fit="max" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flag from './Flag.vue';
import ImgixImage from '../../admin/asset-manager/components/ImgixImage.vue';

export default {
  name: 'MobileMap',

  components: { Flag, ImgixImage },

  props: {
    countries: { required: true, type: Array }
  },

  data() {
    return {
      selectedCountryId: null
    };
  }
};
</script>

<style scoped>
.country-button {
  @apply bg-white rounded px-6 py-3;
  border-top: 4px solid #e61878;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.16);
}
</style>
